import React from "react";

import { Button, Tooltip, makeStyles } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

import { palette } from "../theme";

import { sidebar } from "../Utils/convertValue";
import useCekHakAkses from "../Utils/cekHakAkses";

import AppIcon from "../Assets/Sidebar/Nav_AppIcon.png";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "linear-gradient(0deg, #0A6FC7 0%, #2699FB 100%)",
    width: 70,
    height: "100%",
    "& .simplebar-scrollbar::before": {
      backgroundColor: palette.whiteBlue,
    },
  },
  routeButton: {
    backgroundColor: "transparent",
    display: "flex",
    padding: "1em",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.15)",
    },
    justifyContent: "center",
  },
  routeButtonSelected: {
    backgroundColor: "rgba(0, 0, 0, 0.15)",
  },
}));

const Sidebar = () => {
  const classes = useStyles();
  const cekRole = useCekHakAkses();

  const routes = sidebar.filter((route) => {
    switch (route.label) {
      case "Account":
        return cekRole("a1");
      case "User":
        return cekRole("b1");
      case "Customer Service":
        return cekRole("x1");
      case "Testimoni":
        return cekRole("c1");
      case "Artikel":
        return cekRole("d1");
      case "Gallery":
        return cekRole("e1");
      case "Story":
        return cekRole("t1");
      case "Video":
        return cekRole("f1");
      case "Klinik":
        return cekRole("g1");
      case "Dokter":
        return cekRole("h1");
      case "Tindakan":
        return cekRole("i1");
      case "Promo":
        return cekRole("j1");
      case "Onboarding Login":
        return cekRole("v1");
      case "Banner Promo":
        return cekRole("u1");
      case "FAQ":
        return cekRole("k1");
      case "Asuransi":
        return cekRole("w1");
      case "Appointment":
        return cekRole("l1");
      case "Pembayaran":
        return cekRole("m1");
      case "Poin":
        return cekRole("n1");
      case "Voucher":
        return cekRole("o1");
      case "Voucher Ownership":
        return cekRole("p1");
      case "Chat":
        return cekRole("q1");
      case "Message Center":
        return cekRole("r1");
      case "CSI":
        return cekRole("y1");
      case "Setting":
        return cekRole("s1");
      case "Report":
        return cekRole("s1");
      default:
        return true;
    }
  });

  return (
    <SimpleBar className={classes.root}>
      <Button
        className={classes.routeButton}
        style={{ width: "100%", height: "auto" }}
        disabled
      >
        <img src={AppIcon} alt="Warehouse" />
      </Button>
      {routes.map((route) => (
        <Tooltip key={route.link} title={route.label} placement="right">
          <NavLink
            activeClassName={classes.routeButtonSelected}
            className={classes.routeButton}
            to={route.link}
          >
            {route.icon}
          </NavLink>
        </Tooltip>
      ))}
    </SimpleBar>
  );
};

export default Sidebar;
